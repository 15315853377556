import React from "react";
import { Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";
import styles from "./BotForm.module.css";

const BotFormCheckboxDropdown = ({
  label,
  icon,
  selectedValues,
  handleClick,
  data,
}) => {
  // Function to handle checkbox click
  const handleCheckboxChange = (element) => {
    const isSelected = selectedValues.some((item) => item.id === element.id);
    let updatedSelections;

    if (isSelected) {
      // Remove if already selected
      updatedSelections = selectedValues.filter((item) => item.id !== element.id);
    } else {
      // Add if not selected
      updatedSelections = [...selectedValues, element];
    }

    handleClick(updatedSelections);
  };

  const selectedValuesDisplay =
  selectedValues?.length > 3
    ? `${selectedValues.length} selected`
    : selectedValues.length > 0
    ? selectedValues.map((item) => item.name).join(", ")
    : `Choose Your ${label}`;


  return (
    <div className={styles.formItem}>
      <div className={styles.textIcon}>
        <Icon icon={icon} />
        <span className={styles.label}>{label}</span>
      </div>
      <div className={styles.formItemField}>
        <Dropdown className={styles.dropDown} drop="down">
          <Dropdown.Toggle className={styles.toggle}>
          {selectedValuesDisplay}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.customDropdownMenu}>
            {data.map((element) => (
              <Dropdown.Item
                key={element.id}
                as="div"
                className={styles.customDropdownItem}
                onClick={(e) => {
                  // Stop the event from closing the dropdown when interacting with checkboxes
                  e.stopPropagation();
                }}
              >
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id={`checkbox-${element.id}`}
                    checked={selectedValues.some((item) => item.id === element.id)}
                    onChange={() => handleCheckboxChange(element)}
                    className={styles.checkbox}
                  />
                  <label
                    htmlFor={`checkbox-${element.id}`}
                    className={styles.checkboxLabel}
                  >
                    {element.name}
                  </label>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default BotFormCheckboxDropdown;
