import React, { useState, useEffect, useRef } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Operations from "../../components/charts/Operations";
import Suspects from "../../components/charts/Suspects";
import Messages from "../../components/charts/Messages";
import Bots from "../../components/charts/Bots";
import { useDispatch, useSelector } from "react-redux";
import OperationsMiniCard from "../../shared/MiniTable/OperationsMiniCard";
import BotsMiniCard from "../../shared/MiniTable/BotsMiniCard";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { activeAccount, fetchWithAuth, signOut } from "../../utils/authPopup";
import Spinner from "react-bootstrap/Spinner";
import mapBlur from "../../images/mapcomingsoon.png";
import blur from "../../images/blur.avif";
import blurEntireScreen from "../../../src/images/homeacessdenied.png";
import "../../custom.css";
import brovado from "../../components/charts/No data charts/brovado2.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import LoadingButton from "../../components/shared/LoadingButton";
import { SecondaryButton } from "../../shared/styles/Buttons.styled";
import Text from "./Text";
import PrivacyPolicy from "./PrivacyPolicy";
import { setAgreementStatus } from "../../redux/slices/termsofuse";

function Home({ instance }) {
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const hasAgreed = useSelector((state) => state.termsOfUse.hasAgreed);
  const navigate = useNavigate();
  const [operations, setOperations] = useState(null);
  const [chartData, setChartData] = useState(false);
  const [botData, setBotData] = useState([]);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const [isAgreedEnabled, setIsAgreedEnabled] = useState(false);
  const modalBodyRef = useRef(null);

  const [termsOfUseShort, setTermsOfUseShort] = useState("");
  const [termsOfUseLong, setTermsOfUseLong] = useState("");
  const [agreed, setAgreed] = useState(false);
  function editOrg() {
    navigate("/edit-organization");
  }
  const dispatch = useDispatch();

  const [isModalBodyRendered, setIsModalBodyRendered] = useState(false); // Tracks ModalBody rendering

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      setIsAgreedEnabled(true);
    }
  };

  async function checkTermsOfUse() {
    let userrr = selectedOrg?.users.filter(
      (user) => user?.id == activeAccount?.idTokenClaims?.oid
    );
    if (hasAgreed) {
      setModal(false);
      return;
    }

    if (userrr[0]?.termsOfUse) {
      setModal(true);
      setTermsOfUseShort(Text);
    } else {
      setModal(true);
      setAgreed(true);
      setTermsOfUseLong(PrivacyPolicy);
    }
  }
  const ChangeTersmAgree = async () => {
    let userrr = selectedOrg.users.filter(
      (user) => user.id == activeAccount?.idTokenClaims?.oid
    );
    userrr = { ...userrr[0] };

    userrr.termsofuse = true;

    const data = await fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${selectedOrg.id}/user/${userrr.id}`,
      userrr
    );

    if (data.success) {
      dispatch(setAgreementStatus(true)); // Update Redux and localStorage
      setAgreed(false); // Close the modal
    }
  };

  async function grabOperations() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/operations/active`
      );

      setOperations(data);
    } catch (error) {
      setOperations(null);
      if (error === "Forbidden") setError(true);
    }
  }

  async function displayChartData() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/summary`
      );

      setChartData(data);
    } catch (error) {
      setChartData(false);
      if (error === "Forbidden") setError(true);
    }
  }

  async function grabBots() {
    if (selectedOrg === false) return;
    setError(false);

    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/bots/active`
      );
      setBotData(data);
    } catch (error) {
      setBotData([]);
      if (error === "Forbidden") setError(true);
    }
  }

  useEffect(() => {
    displayChartData();
    grabOperations();
    grabBots();
  }, [selectedOrg]);

  useEffect(() => {
    checkTermsOfUse();
  }, [selectedOrg]);

  function operationDisplay() {
    if (operations === null || operations.length === 0) {
      return (
        <tr>
          <td className="w-75">No Active Operations</td>
        </tr>
      );
    } else {
      return operations.map((item, index) => (
        <tr key={index}>
          <td className="w-75">{item.name}</td>
          <td>
            <Icon icon="material-symbols:info-outline" color="#f8f8f8" />
          </td>
        </tr>
      ));
    }
  }

  function displayBots() {
    if (botData.length === 0) {
      return (
        <tr>
          <td>No Active Bots</td>
        </tr>
      );
    } else {
      return botData.map((item, index) => (
        <tr key={index}>
          <td>{item.properties.Name}</td>
          <td>{item.formattedBotPhoneNumber}</td>
        </tr>
      ));
    }
  }

  return (
    <div className="home">
      <Modal
        backdrop="static"
        keyboard={false}
        modalClassName="custom-modal"
        isOpen={modal}
        scrollable={true}
        toggle={() => setModal(false)}
      >
        <div>
          <ModalHeader>Terms of Service for Transaction Intercept</ModalHeader>
          <ModalBody
            ref={modalBodyRef}
            style={{ overflowY: "auto", maxHeight: "60vh", padding: "16px" }}
            onScroll={handleScroll}
          >
            <Form>{termsOfUseShort ? termsOfUseShort : termsOfUseLong}</Form>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              disabled={false} // {!isAgreedEnabled} - the placeholder terms of use is short, so no scroll to see buttons.  TEMP fix until we have a long terms of use
              onClick={() => {
                if (agreed) {
                  ChangeTersmAgree();
                  setModal(false);
                  dispatch(setAgreementStatus(true));
                } else {
                  dispatch(setAgreementStatus(true));
                  setModal(false);
                }
              }}
              primary
            >
              Agree
            </LoadingButton>

            <SecondaryButton
              color="secondary"
              disabled={false} // {!isAgreedEnabled} - the placeholder terms of use is short, so no scroll to see buttons.  TEMP fix until we have a long terms of use
              onClick={(e) => signOut(e, instance, dispatch)}
            >
              Disagree
            </SecondaryButton>
          </ModalFooter>{" "}
        </div>
      </Modal>
      {error ? (
        <>
          <div className="org-name-container">
            <h1 className="org-name">
              {`${selectedOrg.name}`}{" "}
              <Icon
                cursor="pointer"
                onClick={editOrg}
                icon="fluent:edit-12-regular"
              ></Icon>
            </h1>

            <br></br>
          </div>
          <img src={blurEntireScreen} width={"100%"} />
        </>
      ) : (
        <AuthenticatedTemplate>
          {selectedOrg === false ? (
            <div className="LoadingIcon">
              <Spinner animation="border" />{" "}
            </div>
          ) : (
            <div className="org-name-container">
              <h1 className="org-name">
                {`${selectedOrg.name}`}{" "}
                <Icon
                  cursor="pointer"
                  onClick={editOrg}
                  icon="fluent:edit-12-regular"
                ></Icon>{" "}
              </h1>

              <br />
            </div>
          )}
          {chartData === false || selectedOrg === false ? (
            <div className="LoadingIcon">
              <img width="100%" src={brovado} />{" "}
            </div>
          ) : (
            <div className="charts">
              <Operations
                blur={blur}
                operationsData={chartData?.operationsChartDetails}
              />
              <Messages
                blur={blur}
                messagesData={chartData?.messageChartDetails}
              />
              <Suspects
                blur={blur}
                suspectsData={chartData?.suspectChartDetails}
              />
              <Bots blur={blur} botsData={chartData?.botsChartDetails} />
            </div>
          )}
          {selectedOrg === false ? null : (
            <div className="customLuka">
              <div className="next panel-container">
                <OperationsMiniCard
                  title="Active Operations"
                  content={operationDisplay()}
                />
              </div>
              <div className="next">
                <img
                  style={{ borderRadius: "10px" }}
                  width={"100%"}
                  src={mapBlur}
                />
                {/* <Map
                  position={[36.33982922408236, -87.53951409346624]}
                  markers={markers}
                /> */}
              </div>
              <div className="next bots panel-container">
                <BotsMiniCard
                  title="Active Bots"
                  items={botData.length > 0 ? botData : null}
                  content={displayBots()}
                />
              </div>
            </div>
          )}
        </AuthenticatedTemplate>
      )}
    </div>
  );
}

export default Home;

const markers = [
  [30.248935423456288, -97.943213535544],
  [30.598331828084913, -81.81279229748522],
  [37.297507702341164, -77.62582888999866],
  [35.93472493783899, -86.7908409570775],
  [33.540836614670866, -84.30181662728137],
  [34.99182573071041, -92.48279581978966],
  [39.86123098039068, -85.94187141823231],
];
