import React, { useEffect, useState, useCallback } from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./NavMenu.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { activeAccount, signIn, signOut } from "../../utils/authPopup.js";
import SGLogoTM from "../../images/SGLogoTM.png";
import SGLogo from "../../images/SGLogo.png";
import { Link, useNavigate } from "react-router-dom";
import NewOrg from "../../components/NewOrg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOrg } from "../../redux/slices/organization";
import ThemeButton from "../../components/ThemeButton.js";
import { Icon } from "@iconify/react";
import { useWindowDimensions } from "../../hooks/useGetWindowSize";
import { setAgreementStatus } from "../../redux/slices/termsofuse.js";

function DesktopNavMenu({
  instance,
  superAdminShow,
  orgDropItems,
  showModal,
  setShowModal,
  selectedOrg,
  navHome,
  handleLogin,
}) {
  const dispatch = useDispatch();

  return (
    <Navbar
      className="navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 "
      light
    >
      <NavbarBrand className="d-flex align-items-center user-text">
        {activeAccount ? (
          <>
            <img
              src={SGLogo}
              className="sg-logo"
              height="40"
              alt="Street Grace Logo"
            />
            <span onClick={navHome}>Welcome, {activeAccount.name}</span>
            <div className="hi ms-3">
              <DropdownButton title={selectedOrg.name}>
                {orgDropItems}
                {superAdminShow && (
                  <Dropdown.Item
                    className="dropItem"
                    onClick={() => setShowModal(true)}
                  >
                    + Add New
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
          </>
        ) : (
          <img
            src={SGLogoTM}
            className="sg-logo"
            height="40"
            alt="Street Grace Logo TM"
          />
        )}
      </NavbarBrand>
      {showModal ? (
        <NewOrg
          instance={instance}
          show={showModal}
          openModal={() => setShowModal(true)}
          closeModal={() => setShowModal(false)}
        />
      ) : null}

      <ul className="rightContainer">
        <AuthenticatedTemplate>
          <ThemeButton />
          <Link to={"help"} className="login">
            Help
          </Link>
          <p
            className="login"
            title="Sign Out"
            onClick={(e) => signOut(e, instance, dispatch)}
          >
            Sign Out
          </p>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <p className="login" title="Sign In" onClick={handleLogin}>
              Sign In
            </p>
          </div>
        </UnauthenticatedTemplate>
      </ul>
    </Navbar>
  );
}

function MobileNavMenu({
  instance,
  superAdminShow,
  orgDropItems,
  showModal,
  setShowModal,
  selectedOrg,
  navHome,
  handleLogin,
}) {
  const dispatch = useDispatch();
  const [isPanelOpen, setPanelOpen] = useState(false);

  return (
    <>
      <Navbar className="border-bottom navBarMobile" light>
        {activeAccount ? (
          <>
            <div className="navMenuAndLogo">
              <div onClick={() => setPanelOpen(true)}>
                <Icon
                  icon="arcticons:hamburger-menu"
                  width="30"
                  height="30"
                  style={{ color: "#fffafa" }}
                />
              </div>
              <img
                src={SGLogo}
                className="sg-logo"
                height="40"
                alt="Street Grace Logo"
              />
            </div>
            <div className="hi ms-3">
              <DropdownButton title={selectedOrg.name}>
                {orgDropItems}
                {superAdminShow && (
                  <Dropdown.Item
                    className="dropItem"
                    onClick={() => setShowModal(true)}
                  >
                    + Add New
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
          </>
        ) : (
          <img
            src={SGLogo}
            className="sg-logo"
            height="40"
            alt="Street Grace Logo TM"
          />
        )}
        <ul className="rightContainer">
          <UnauthenticatedTemplate>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <p className="login" title="Sign In" onClick={handleLogin}>
                Sign In
              </p>
            </div>
          </UnauthenticatedTemplate>
        </ul>
      </Navbar>
      {isPanelOpen && (
        <div className="navBarPanel">
          <div className="navBarPanelHeader">
            <div onClick={() => setPanelOpen(false)}>
              <Icon
                icon="material-symbols-light:close"
                width="30"
                height="30"
                style={{ color: "#fffafa" }}
              />
            </div>
            <img
              src={SGLogo}
              className="sg-logo"
              height="40"
              alt="Street Grace Logo"
            />
          </div>
          <h1>{activeAccount.name}</h1>
          <ThemeButton />
          <div>
            <a
              className="login"
              href="https://www.streetgrace.org/ti-faq"
              target="_blank"
            >
              Help
            </a>
          </div>
          <button
            className="navBarSignOut"
            onClick={(e) => signOut(e, instance, dispatch)}
          >
            Sign Out
          </button>
        </div>
      )}
    </>
  );
}

function NavMenu({ superAdminShow }) {
  const [orgDropItems, setOrgDropItems] = useState([]);
  const dispatch = useDispatch();
  const orgData = useSelector((state) => state.organization.data);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const windowSize = useWindowDimensions();
  const isSmallScreen = windowSize.width < 678;

  const navigate = useNavigate();

  const { instance } = useMsal();

  const [showModal, setShowModal] = useState(false);

  const handleOrgChange = useCallback(
    (e) => {
      e.preventDefault();
      const newOrg = orgData.filter((data) => data.id === e.target.id);
      dispatch(setSelectedOrg(newOrg[0]));
      dispatch(setAgreementStatus(false));
    },
    [orgData, dispatch]
  );

  useEffect(() => {
    const orgItems = orgData.map((data) => {
      return data.id !== selectedOrg.id ? (
        <Dropdown.Item
          key={data.id}
          className="dropItem"
          as="button"
          onClick={handleOrgChange}
          name={data.name}
          id={data.id}
        >
          {data.name}
        </Dropdown.Item>
      ) : null;
    });

    setOrgDropItems(orgItems);
  }, [handleOrgChange, orgData, selectedOrg.id]);

  function handleLogin(e) {
    signIn(e, instance);
    navigate("/");
  }

  function navHome() {
    navigate("/");
  }
  function handleHelpPage() {
    navigate("/help");
  }

  return (
    <header className="head">
      {isSmallScreen ? (
        <MobileNavMenu
          instance={instance}
          superAdminShow={superAdminShow}
          orgDropItems={orgDropItems}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedOrg={selectedOrg}
          handleLogin={handleLogin}
          navHome={navHome}
          handleHelpPage={handleHelpPage}
        />
      ) : (
        <DesktopNavMenu
          instance={instance}
          superAdminShow={superAdminShow}
          orgDropItems={orgDropItems}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedOrg={selectedOrg}
          handleLogin={handleLogin}
          navHome={navHome}
          handleHelpPage={handleHelpPage}
        />
      )}
    </header>
  );
}

export default NavMenu;
