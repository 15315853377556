import React, { useState, useEffect, useRef } from "react";
import "./Help.css";
import { Icon } from "@iconify/react";
import "../../custom.css";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../shared/styles/Buttons.styled";
import BotFormDropdown from "../../components/AddBot/BotFormDropdown";
import { Dropdown } from "react-bootstrap";
import { fetchWithAuth } from "../../utils/authPopup";
import LoadingButton from "../../components/shared/LoadingButton";
import { useSelector } from "react-redux";
import { isEqual } from "date-fns";

const Help = ({ instance, organizationId, superAdminShow }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [category, setCategory] = useState("Show All");
  const [category2, setCategory2] = useState("Show All");
  const [lawCheckRequired, setLawCheckRequiredsetfirst] = useState(false);
  const [videoUrls, setVideoUrls] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [previewSrc, setPreviewSrc] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqBox, setFaqBox] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  const isVideoFile = (details) =>
    /\.(mp4|mov|avi|mkv)$/.test(details.toLowerCase());

  const toggleAnswer = async (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
      const item = faqBox.find((item) => item.id === index);
      if (isVideoFile(item.details)) {
        try {
          const response = await fetchWithAuth(
            instance,
            "GET",
            `api/faq/GetMediaUrl/${item.details}`
          );
          setVideoUrls((prevVideoUrls) => ({
            ...prevVideoUrls,
            [index]: response.url,
          }));
        } catch (error) {
          console.error("Error fetching video URL:", error);
        }
      }
    }
  };

  const FetchFaqItems = async () => {
    const response = await fetchWithAuth(instance, "GET", `api/faq`);
    return response;
  };

  useEffect(() => {
    FetchFaqItems().then((res) => {
      setFaqBox(res);
    });
  }, []);

  const addFAQ = async () => {
    if (!title || !category || !question || !answer) {
      alert("All fields are required.");
      return;
    }
    const faqObject = {
      id: isEditing ? editId : "",
      Category: category2,
      Title: title,
      mediaUrl: "",
      question: question,
      Details: answer,
      RequiresLawEnforcement: lawCheckRequired,
    };

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("operationId", "");

      const data = await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/media/upload`,
        formData
      );
      faqObject.mediaUrl = data.url;
    }
    handleRemoveFile();
    const method = isEditing ? "PUT" : "POST";
    const endpoint = `api/faq`;

    let result = await fetchWithAuth(instance, method, endpoint, faqObject);
    setModal(!modal);
    setQuestion("");
    setAnswer("");
    setTitle("");
    setIsEditing(false);
    setCategory2("Show All");
    if (result) {
      FetchFaqItems().then((res) => {
        setFaqBox(res);
      });
    }
  };

  const startEditing = (item) => {
    setEditId(item.id);
    setTitle(item.title);
    setQuestion(item.question);
    setAnswer(item.details);
    setCategory2(item.category);
    setLawCheckRequiredsetfirst(item.RequiresLawEnforcement);
    setModal(true);
    setIsEditing(true);
  };

  const categoryData = [
    { name: "Bot Management" },
    { name: "Chats" },
    { name: "General" },
    { name: "Operations" },
    { name: "Show All" },
    { name: "Tutorials" },
    { name: "User Management" },
  ];
  const getFaqItems = () => {
    const filteredFaqs =
      category === "Show All"
        ? faqBox
        : faqBox.filter((item) => item.category === category);

    return filteredFaqs.filter((item) =>
      item.question.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const faqItems = getFaqItems();

  const toggle = () => {
    setModal(!modal);
    setCategory2("Show All");
    setTitle("");
    setQuestion("");
    setAnswer("");
    setIsEditing(false);
  };
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewSrc(null);
    fileInputRef.current.value = null;
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setPreviewSrc(URL.createObjectURL(e.target.files[0]));
  };
  const toTitleCase = (str) => {
    let a = str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return a;
  };

  return (
    <div className="faq-container">
      <div className="center">
        <div style={{ display: "flex" }}>
          <h1 className={`${themeClass} faqheader`}>FAQ</h1>{" "}
          <Dropdown>
            <Dropdown.Toggle>{category}</Dropdown.Toggle>
            <Dropdown.Menu>
              {categoryData.map((element, index) => (
                <Dropdown.Item
                  key={index}
                  eventKey={element.name}
                  active={category === element.name}
                  onClick={() => setCategory(element.name)}
                >
                  {element.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Input
            type="text"
            placeholder="Search FAQs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="faq-search-bar"
          />
        </div>
        {superAdminShow && (
          <PrimaryButton
            onClick={toggle}
            style={{ minWidth: "100px" }}
            className="px-3 py-2 font-size-sm font-size-md-base"
          >
            <Icon icon="ic:baseline-plus" color="white" /> Add new
          </PrimaryButton>
        )}
      </div>
      <Modal style={{ color: "black" }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {isEditing ? "Edit FAQ" : "Create New FAQ!"}{" "}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Dropdown>
                <Dropdown.Toggle
                  style={{ color: "white", border: "1px solid white" }} // Set font color to white
                >
                  {category2}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {categoryData.map((element, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={element.name}
                      active={category2 === element.name}
                      onClick={() => setCategory2(element.name)}
                    >
                      {element.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>{" "}
            </FormGroup>{" "}
            <FormGroup required>
              <Label for="Category">
                Title <span style={{ color: "red" }}> *</span>
              </Label>
              <Input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                type="text"
                name="Category"
                id="Category"
              />
            </FormGroup>
            <FormGroup>
              <Label for="Title">Question</Label>
              <Input
                onChange={(e) => setQuestion(toTitleCase(e.target.value))}
                value={question}
                type="text"
                name="Title"
                id="Title"
              />
            </FormGroup>
            <FormGroup>
              <Label for="Details">Answer</Label>
              <Input
                onChange={(e) => setAnswer(toTitleCase(e.target.value))}
                value={answer}
                type="text"
                name="Details"
                id="Details"
              />
            </FormGroup>
            <FormGroup
              style={{
                marginTop: "10px",
              }}
            >
              <Label for="RequiresLawEnforcement">
                Requires Law Enforcement
              </Label>
              <Input
                value={lawCheckRequired}
                onChange={() => setLawCheckRequiredsetfirst((prev) => !prev)}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  width: "16px",
                  height: "16px",
                }}
                type="checkbox"
                name="RequiresLawEnforcement"
                id="RequiresLawEnforcement"
              />
              <div
                style={{
                  position: "relative",
                  height: "50px",
                  display: "flex",
                  alignItemsL: "center",
                }}
              >
                <Icon
                  style={{
                    height: "50px",
                    cursor: "pointer",
                  }}
                  icon="ic:sharp-upload-file"
                  fontSize={26}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    cursor: "pointer",

                    height: "100%",
                    opacity: 0,
                    zIndex: 1000,
                  }}
                />
                <p
                  style={{
                    margin: 0,
                    position: "absolute",
                    right: 0,
                    top: 2,
                  }}
                >
                  {previewSrc && (
                    <img
                      src={previewSrc}
                      alt="preview"
                      style={{
                        width: "100px",
                        height: "50px",

                        cursor: "pointer",
                      }}
                      onClick={handleRemoveFile}
                    />
                  )}{" "}
                </p>{" "}
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <LoadingButton oading={loading} primary onClick={addFAQ}>
            Submit
          </LoadingButton>
          <SecondaryButton color="secondary" onClick={toggle}>
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
      {[...faqItems]
        .sort((a, b) => a.question.localeCompare(b.question))
        .map((item, index) => (
          <div key={item.id} className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(item.id)}>
              <span>{item.question}</span>
              <button className="faq-toggle-button">
                {openIndex === item.id ? "-" : "+"}
              </button>
              {superAdminShow && (
                <Icon
                  icon="ic:baseline-edit"
                  className="edit-icon"
                  onClick={() => startEditing(item)}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                />
              )}
            </div>
            {openIndex === item.id && (
              <div className="faq-answer">
                {isVideoFile(item.details) ? (
                  videoUrls[item.id] ? (
                    <video
                      src={`api${videoUrls[item.id]}`}
                      type="video/mp4"
                      controls
                      style={{ width: "50%" }}
                    />
                  ) : (
                    <p>Loading video...</p>
                  )
                ) : (
                  <p className={`${themeClass} paragraph`}>{item.details}</p>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Help;
