import { createSlice } from "@reduxjs/toolkit";

const savedAgreementStatus = localStorage.getItem("hasAgreed") === "true";

const termsOfUseSlice = createSlice({
  name: "termsOfUse",
  initialState: {
    hasAgreed: savedAgreementStatus || false,
  },
  reducers: {
    setAgreementStatus: (state, action) => {
      state.hasAgreed = action.payload;
      localStorage.setItem("hasAgreed", action.payload);
    },
    clearAgreementStatus: (state) => {
      state.hasAgreed = false;
      localStorage.removeItem("hasAgreed");
    },
  },
});

export const { setAgreementStatus, clearAgreementStatus } =
  termsOfUseSlice.actions;

export default termsOfUseSlice.reducer;
