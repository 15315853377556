import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { InfoIcon } from "../../shared/styles/InfoIcon.styled";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../shared/styles/Buttons.styled";
import { fetchWithAuth } from "../../utils/authPopup.js";
import LoadingButton from "../shared/LoadingButton.js";

const AddOperation = ({ instance, organizationId, updateOpList }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validLoading, setValidLoading] = useState(false);

  const [deterrenceMessage, setDeterrenceMessage] = useState(
    "You are now permanently registered in the system as someone seeking to purchase sex online from an exploited individual.   The people in these ads are under force, fraud, and coercion, and you are directly contributing to their abuse by being part of the demand for HUMAN TRAFFICKING.   The details of this incident will be reviewed further, and law enforcement may be contacted, questioned or charged by local, state, or federal law enforcement.   For your sake and for the sake of others, please consider getting help now before it is too late. Remember, any and all further attempts to purchase sex will be reported immediately."
  );

  const [valid, setValid] = useState(false);
  const [zipcode, setzipCode] = useState("");
  const [zipCodes, setZipCodes] = useState([]);
  const toggle = () => {
    setName("");
    setLocation("");
    setStartDate("");
    setEndDate("");
    setModal(!modal);
    setValid(false);
    setzipCode("");
    setZipCodes([]);
    setLoading(false);
  };

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!name || !startDate || !endDate) {
      alert("Please fill in all fields.");
      setLoading(false);

      return;
    }
    if (!valid) {
      alert("Please Validate The Zip Code.");
      setLoading(false);

      return;
    }

    const now = new Date().toISOString();
    if (new Date(endDate).toISOString() <= now) {
      alert("End Date must be in the future.");
      setLoading(false);

      return;
    }

    try {
      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/operations/`,
        {
          deterrenceMessage,
          name,
          location: zipCodes.results[0].address?.postalName,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
          isActive: true,
          fileUrl: "",
          locationDetails: {
            zipcode: zipcode,
            state: zipCodes.results[0].address?.countrySubdivisionName,
            country: zipCodes.results[0].address?.countryCode,
            lat: zipCodes.results[0].position?.lat,
            lng: zipCodes.results[0].position?.lon,
            city: zipCodes.results[0].address?.postalName,
          },
        }
      );
      toggle();
      updateOpList();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getZip = async () => {
    try {
      if (zipcode) {
        setValidLoading(true);
        const data = await fetchWithAuth(
          instance,
          "GET",
          `api/location/${zipcode}`
        );
        if (data) {
          setZipCodes(data);
          setValid(true); //
          console.log(data);
          setValidLoading(false);
        } else {
          setValidLoading(false);
        }
      } else {
        alert("Fill Zip Code Filed");
      }
    } catch (error) {
      console.error("Error fetching zip codes:", error);
    }
  };

  const handleZipValid = (e) => {
    setzipCode(e.target.value);
    setValid(false);
  };
  return (
    <div>
      <PrimaryButton
        onClick={toggle}
        style={{ minWidth: "100px" }}
        className="px-3 py-2 font-size-sm font-size-md-base"
      >
        <Icon icon="ic:baseline-plus" color="white" /> Add new
      </PrimaryButton>
      <Modal style={{ color: "black" }} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create new operation</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Zip Code</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Input
                  style={{ width: "60%" }}
                  type="text"
                  name="Zip Code"
                  id="zipcode"
                  value={zipcode}
                  onChange={(e) => handleZipValid(e)}
                />{" "}
                {valid ? (
                  <InfoIcon
                    style={{ margin: "0" }}
                    icon="fluent-mdl2:completed-solid"
                    color="#7ef182"
                  />
                ) : (
                  <InfoIcon
                    style={{ margin: "0" }}
                    icon="fluent-mdl2:status-error-full"
                    color="#f17e7e"
                  />
                )}
                <LoadingButton onClick={() => getZip()} loading={validLoading}>
                  Validate Zip Code
                </LoadingButton>
              </div>
            </FormGroup>{" "}
            {valid && zipCodes && (
              <FormGroup>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Label for="location">
                    <span style={{ fontWeight: "bolder" }}> Municipality:</span>{" "}
                    {zipCodes.results[0].address?.postalName}{" "}
                  </Label>
                  <Label for="location">
                    <span style={{ fontWeight: "bolder" }}> State: </span>{" "}
                    {zipCodes.results[0].address?.countrySubdivisionName}{" "}
                  </Label>
                  <Label for="location">
                    <span style={{ fontWeight: "bolder" }}> Country:</span>{" "}
                    {zipCodes.results[0].address?.countryCode}{" "}
                  </Label>
                </div>
              </FormGroup>
            )}
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                required
                type="datetime-local"
                name="startDate"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                required
                type="datetime-local"
                name="endDate"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <LoadingButton onClick={handleSubmit} loading={loading} primary>
            Submit
          </LoadingButton>
          <SecondaryButton color="secondary" onClick={toggle}>
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddOperation;
