import React from "react";

const Text = () => {
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
      }}
    >

      <h2>Purpose</h2>
      <p>The purpose of the Transaction Intercept Platform is to provide the ability to communicate with and ultimately deter or arrest potential purchasers of illegal commercial sex who contact Transaction Intercept through an advertisement placed on a third-party website by a registered and licensed user.</p>
      <h2>Third Party Services</h2>
      <p>Your use of Transaction Intercept will depend upon the use of third-party websites or services that we do not own or control. Your use of the Transaction Intercept Platform may also include the use of applications that are developed or owned by a third party. Your use of such third-party applications, websites, and services is governed by that party's own terms of service or privacy policies.</p>

{/*
      <h1>ABOUT STREET GRACE</h1>
      <p>
        Street Grace received our 501c3 in January 2009. Launched by the
        Christian faith community in Atlanta, we are now located in Atlanta,
        Tennessee, Texas, and California while, through the use of technology,
        we are working in 18 states. Street Grace is frequently recognized as
        the preeminent leader in the fight against the commercial sexual
        exploitation of children through evidence-based demand reduction
        strategies. With a network of over 150 churches, 75 businesses,
        countless community partners, and more than 1,500 volunteers, our
        programs now reach over 75,000 individuals each year, with more than
        50,000 of them being between the ages of 12-18.
      </p>
      <h2>Gracie Exists to:</h2>
      <ol>
        <li>
          Eradicate the commercial sexual exploitation of our children
          specifically by removing the cloak of anonymity that has allowed this
          illegal industry to flourish and that buyers of sex have benefited
          from to date.
        </li>
        <li>
          Disrupt transactions before they occur by “catching” potential buyers,
          collecting data and information on them, and informing them that the
          information has been collected and set up in a permanent file
          associated with their number. Activities (calls, voicemails, texts,
          pictures) are made available to Law Enforcement (LE) in their area.
          Additionally, informing them that what they have just done is a felony
          in all 50 states and punishable by up to 40 years in prison and $100K
          in fines.
        </li>
        <li>
          Share information with LE as actionable and appropriate. The
          information can be sorted as needed by jurisdiction or geography.
        </li>
        <li>
          Collaborate with LE so arrests can be made during LE operations and
          prosecutions can occur.
        </li>
        <li>
          Redirect buyers, by the tens of thousands, to Christ-centered
          resources for help. Resources are available face-to-face,
          telephone-based, web-based, webinars, and more. These resources are
          fully trained, certified, or licensed in trauma, sex addiction,
          addiction, domestic violence, etc., and are virtually all
          Christ-centered.
        </li>
        <li>
          Reduce the percentage of repeat callers by greater than 50% in markets
          where Gracie is deployed.
        </li>
        <li>
          Be the first to demonstrate a marketplace reduction can occur and be
          measured.
        </li>
      </ol>
      <p>
        Gracie is funded by the generous donations of time and dollars from
        Street Grace’s supporters.
      </p>
      <h2>General Procedures</h2>
      <p>
        May want to reference copyrights to SG assets (logo), patents for
        software, and ensure that the intellectual property is protected.
      </p>
      <h2>Terms of Usage</h2>
      <p>
        An agency in good standing with Street Grace (considered the Host
        agency) is eligible to use the OperationGracie platform for their
        purposes, whether for research and data collection, law enforcement
        “sting” operations, or deterrence operations. Users must use the
        platform for its intended purposes and must not modify or maliciously
        attack the site/software.
      </p>
      <p>
        “Good standing” is established when an agency administrator is trained
        in using the tool. Each agency must maintain a minimum of one
        administrator. Agencies may be required to sign NDAs.
      </p>
      <p>
        No operation may be conducted without the express approval of Street
        Grace. Street Grace reserves the right to deny, postpone, or terminate
        any operation for any reason.
      </p>
      <p>
        Street Grace makes no guarantees that the site will work without errors
        and imposes no SLA for repairs. Indemnification and liability limits
        apply to all users.
      </p>
      <h3>Users</h3>
      <ul>
        <li>
          <strong>Super-Administrators:</strong> Designated by Street Grace to
          troubleshoot, train, and monitor site usage. Super-administrators can
          manage agencies and assign initial administrators.
        </li>
        <li>
          <strong>Administrators:</strong> Primary custodians of the agency’s
          operations and users. They manage user access, assign agents, and
          oversee operations.
        </li>
        <li>
          <strong>Agents:</strong> Authorized to access operational tools like
          the Dashboard, Chat, and Models pages.
        </li>
        <li>
          <strong>Researchers:</strong> Granted limited access to anonymized
          data without direct access to the web app.
        </li>
      </ul>
      <h2>False Positives</h2>
      <p>
        A false positive describes a suspect who interacts with the platform but
        is not a target of the operation. Agents must close the conversation and
        report it to Street Grace for governance.
      </p>
      <h2>Mandatory Reporting</h2>
      <p>
        Agents encountering inappropriate content (e.g., self-harm, child
        pornography, abuse) must report it to Street Grace and the appropriate
        authorities. Street Grace maintains a separate record for such data.
      </p>
      <h2>Data Retention</h2>
      <p>
        User data is managed by AuthO under its data retention policy. Operation
        metadata (e.g., start/end dates, agent participation) is retained
        indefinitely for analytics purposes. After the retention period, phone
        numbers are hashed, and PII is redacted. Anonymized data is stored in a
        research database, and original data is deleted.
      </p>
      <p>
        For complete details, refer to data governance policies or contact
        Street Grace directly.
      </p>
*/}
    </div>
  );
};

export default Text;
