import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import organizationReducer from "./slices/organization";
import operationReducer from "./slices/operation";
import suspectReducer from "./slices/suspect";
import settingsReducer from "./slices/settings";
import themeReducer from "./slices/themeSlice.js";
import thermsOfUse from "./slices/termsofuse.js";
export default configureStore({
  reducer: {
    auth: authReducer,
    organization: organizationReducer,
    operation: operationReducer,
    suspect: suspectReducer,
    settings: settingsReducer,
    theme: themeReducer,
    termsOfUse: thermsOfUse,
  },
});
