import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import generatePDF from "react-to-pdf";
import { useSelector } from "react-redux";
import { fetchWithAuth } from "../utils/authPopup";

const ExportContext = ({
  instance,
  selectedChat,
  organizationId,
  containerRef,
}) => {
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);

  const handleOpenExportDialog = () => {
    setOpenExportDialog(true);
  };

  const handleCloseExportDialog = () => {
    setOpenExportDialog(false);
  };

  const handleExport = async () => {
    if (!selectedChat?.id) return alert("Pick a conversation first!");
    if (!selectedOrg?.id) return alert("Pick an organization first!");

    const originalHeight = containerRef.current.style.height;
    const originalOverflow = containerRef.current.style.overflow;

    // Add metadata
    const metadataDiv = document.createElement("div");
    metadataDiv.style.margin = "-10px";
    metadataDiv.style.marginBottom = "20px";
    metadataDiv.style.fontSize = "16px";
    metadataDiv.style.backgroundColor = "white";
    metadataDiv.style.color = "black";
    metadataDiv.innerHTML = `
      <strong>Exported Conversation</strong><br/>
      <em>Conversation ID: ${selectedChat.id}</em><br/>
      <em>Organization Name: ${selectedOrg.name}</em><br/>
      <em>Bot Name: ${selectedChat.botName}</em><br/>
      <em>Bot Description: ${selectedChat.botDescription}</em><br/>
      <em>Suspect Name: ${selectedChat.suspect.name}</em><br/>
      <em>Suspect Number: ${selectedChat.suspect.formattedPhoneNumber}</em><br/>
      <em>Taken Over By: ${selectedChat.takeoverUserName}</em><br/>
      <em>Date: ${new Date().toLocaleDateString()}</em>
    `;

    containerRef.current.insertBefore(
      metadataDiv,
      containerRef.current.firstChild
    );

    // Adjust container for full content
    containerRef.current.style.height = `${containerRef.current.scrollHeight}px`;
    containerRef.current.style.overflow = "visible";

    generatePDF(containerRef, {
      filename: "export.pdf",
      method: "save",
      resolution: 3,
      page: {
        margin: { top: 0, right: 10, bottom: 0, left: 10 },
        format: "a4",
        orientation: "portrait",
      },
      canvas: {
        mimeType: "image/jpeg",
        qualityRatio: 1,
      },
      overrides: {
        pdf: {},
        canvas: {
          useCORS: true,
        },
      },
    });

    // Restore original styles and remove metadata
    containerRef.current.style.height = originalHeight;
    containerRef.current.style.overflow = originalOverflow;
    containerRef.current.removeChild(metadataDiv);

    await fetchWithAuth(
      instance,
      "POST",
      `api/organization/${organizationId}/conversations/${selectedChat?.id}/export`
    );
  };

  return (
    <>
      <Button
        onClick={handleOpenExportDialog}
        variant="contained"
        color="primary"
        size="large"
        sx={{
          "&.Mui-disabled": {
            color: "white",
          },
        }}
        style={{ backgroundColor: "blue", color: "white" }}
      >
        Export
      </Button>

      <Dialog
        open={openExportDialog}
        onClose={handleCloseExportDialog}
        aria-labelledby="export-dialog-title"
        aria-describedby="export-dialog-description"
      >
        <DialogTitle id="export-dialog-title">Confirm Export</DialogTitle>
        <DialogContent>
          <DialogContentText id="export-dialog-description">
            Please confirm that you'd like to export this conversation. All
            Exports are logged & tracked in the system for auditing purposes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExport} color="primary">
            Confirm Export
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportContext;
