import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
      }}
    >

      <h2>Purpose</h2>
      <p>The purpose of the Transaction Intercept Platform is to provide the ability to communicate with and ultimately deter or arrest potential purchasers of illegal commercial sex who contact Transaction Intercept through an advertisement placed on a third-party website by a registered and licensed user.</p>
      <h2>Third Party Services</h2>
      <p>Your use of Transaction Intercept will depend upon the use of third-party websites or services that we do not own or control. Your use of the Transaction Intercept Platform may also include the use of applications that are developed or owned by a third party. Your use of such third-party applications, websites, and services is governed by that party's own terms of service or privacy policies.</p>

{/*
      <h1>ONLINE PRIVACY POLICY AGREEMENT</h1>
      <p>
        <strong>Date:</strong> September 26, 2024
      </p>
      <p>
        Street Grace (Street Grace) values its users' privacy. This Privacy
        Policy ("Policy") will help you understand how we collect and use
        personal information from those who visit our website or make use of our
        online facilities and services, and what we will and will not do with
        the information we collect.
      </p>
      <p>
        Our Policy has been designed and created to ensure those affiliated with
        Street Grace of our commitment and realization of our obligation not
        only to meet, but to exceed, most existing privacy standards.
      </p>
      <p>
        We reserve the right to make changes to this Policy at any given time.
        If you want to make sure that you are up to date with the latest
        changes, we advise you to frequently visit this page. If at any point in
        time Street Grace decides to make use of any personally identifiable
        information on file, in a manner vastly different from that which was
        stated when this information was initially collected, the user or users
        shall be promptly notified by email. Users at that time shall have the
        option as to whether to permit the use of their information in this
        separate manner.
      </p>
      <p>
        This Policy applies to Street Grace and any associated platform and
        websites, and it governs any and all data collection and usage by us.
        Through the use of streetgrace.org and any subsidiary website listed
        below, you are therefore consenting to the data collection procedures
        expressed in this Policy.
      </p>
      <p>
        Please note that this Policy does not govern the collection and use of
        information by companies that Street Grace does not control, nor by
        individuals not employed or managed by us. If you visit a website that
        we mention or link to, be sure to review its privacy policy before
        providing the site with information. It is highly recommended and
        suggested that you review the privacy policies and statements of any
        website you choose to use or frequent to better understand the way in
        which websites garner, make use of, and share the information collected.
      </p>
      <h2>Specifically, this Policy will inform you of the following:</h2>
      <ol>
        <li>
          What personally identifiable information is collected from you through
          our website;
        </li>
        <li>
          Why we collect personally identifiable information and the legal basis
          for such collection;
        </li>
        <li>
          How we use the collected information and with whom it may be shared;
        </li>
        <li>
          What choices are available to you regarding the use of your data; and
        </li>
        <li>
          The security procedures in place to protect the misuse of your
          information.
        </li>
      </ol>
      <h2>Information We Collect</h2>
      <p>
        It is always up to you whether to disclose personally identifiable
        information to us, although if you elect not to do so, we reserve the
        right not to register you as a user or provide you with any products or
        services. This website collects various types of information, such as:
      </p>
      <ul>
        <li>
          Voluntarily provided information which may include your name, address,
          email address, billing and/or credit card information, etc., which may
          be used when you purchase products and/or services and to deliver the
          services you have requested.
        </li>
        <li>
          Information automatically collected when visiting our website, which
          may include cookies, third-party tracking technologies, and server
          logs.
        </li>
      </ul>
      <p>
        In addition, Street Grace may have the occasion to collect non-personal
        anonymous demographic information, such as age, gender, household
        income, political affiliation, race, and religion, as well as the type
        of browser you are using, IP address, or type of operating system, which
        will assist us in providing and maintaining superior quality service.
      </p>
      <p>
        Street Grace may also deem it necessary, from time to time, to follow
        websites that our users may frequent to glean what types of services and
        products may be the most popular to customers or the general public.
      </p>
      <p>
        Please rest assured that this site will only collect personal
        information that you knowingly and willingly provide to us by way of
        surveys, completed membership forms, and emails. It is the intent of
        this site to use personal information only for the purpose for which it
        was requested and any additional uses specifically provided for in this
        Policy.
      </p>
      <h2>Why We Collect Information and For How Long</h2>
      <p>We are collecting your data for several reasons:</p>
      <ul>
        <li>
          To better understand your needs and provide you with the services you
          have requested;
        </li>
        <li>
          To fulfill our legitimate interest in improving our services and
          products;
        </li>
        <li>
          To send you promotional emails containing information we think you may
          like when we have your consent to do so;
        </li>
        <li>
          To contact you to fill out surveys or participate in other types of
          market research, when we have your consent to do so;
        </li>
        <li>
          To customize our website according to your online behavior and
          personal preferences.
        </li>
      </ul>
      <p>
        The data we collect from you will be stored for no longer than
        necessary. The length of time we retain said information will be
        determined based upon the following criteria: the length of time your
        personal information remains relevant; the length of time it is
        reasonable to keep records to demonstrate that we have fulfilled our
        duties and obligations; any limitation periods within which claims might
        be made; any retention periods prescribed by law or recommended by
        regulators, professional bodies, or associations; the type of contract
        we have with you, the existence of your consent, and our legitimate
        interest in keeping such information as stated in this Policy.
      </p>
*/}
    </div>
  );
};

export default PrivacyPolicy;
