import React, { useEffect, useState } from "react";
import "./EditBot.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { fetchWithAuth } from "../../utils/authPopup.js";
import { setSelectedOp } from "../../redux/slices/operation.js";

function EditBot(props) {
  const dispatch = useDispatch();
  const [botPersonaName, setBotPersonaName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    address: "",
  });
  const { instance, organizationId } = props;
  const [description, setDescription] = useState("");

  const [data, setData] = useState([]);
  const { id } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const opname = queryParams.get("opname"); // Retrieves the query parameter `opname`

  async function getEditBot() {
    const data = await fetchWithAuth(
      instance,
      "GET",
      `api/organization/${organizationId}/bots/${id}`
    );

    const data2 = await fetchWithAuth(instance, "GET", `api/botPersona`);
    const filterdata = data2.filter((el) => el.id == data?.botPersonaId)[0];
    setBotPersonaName(filterdata.name);

    return data;
  }

  const updateBot = (e) => {
    e.preventDefault();
    const botObject = {
      id: id,
      name: data?.properties.Name,
      description: description,
      conversationStatistics: {
        countOfBotConversations: 10,
        countOfConversations: 20,
        countOfHumanConversations: 15,
      },
      languages: data?.languages,
      isActive: true,
      organizationId: organizationId,
      phoneNumber: data?.phoneNumber,
      operationId: data?.operationId,
      botPersonaId: data?.botPersonaId,
      properties: data?.properties,
      responseDelaySecondsMax: 10,
      responseDelaySecondsMin: 5,
    };
    return fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${organizationId}/bots/${id}`,
      botObject
    );
  };

  useEffect(() => {
    getEditBot().then((element) => {
      setData(element);
      setDescription(element.description);
    });
  }, []);

  const navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  return (
    <div>
      <br></br>
      <div className="custom-div">
        <div className="navigateBox">
          <span className="navigateBtn" onClick={() => navigate("/bots")}>
            All Bots
          </span>
          <Icon
            style={{ fontSize: "17px", marginTop: "1px" }}
            icon="mingcute:right-fill"
          />
          <span> Edit Bot</span>
        </div>
        <div className="BotDisplayContainer">
          <div className="BotsHeaderLeft">
            <div className="BotTitle">Edit Bot</div>
            <br></br>
          </div>{" "}
          <br></br>
          Operation Name:<span> </span>
          <span
            className="navigateBtn"
            onClick={() => {
              navigate(`/operations/operation/${data.operationId}`);
            }}
          >
            {opname}{" "}
          </span>
          <hr className="BreakStyle" />
          <h4>Information</h4>
          <div className="EditBotContainer">
            <div className="EditContainer1">
              <span className="EditSpanFlex">
                <Icon
                  icon="mdi:account"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Name: </span>{" "}
                {data.properties?.Name}
              </span>
              <span className="EditSpanFlex">
                <Icon
                  icon="mdi:phone"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Phone number: </span>{" "}
                {data.phoneNumber}
              </span>{" "}
              <span className="EditSpanFlex">
                <Icon
                  icon="tdesign:emo-emotional"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>BotPersona Name: </span>{" "}
                {botPersonaName}
              </span>
              <span className="EditSpanFlex">
                <Icon
                  icon="material-symbols:view-agenda"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Age: </span>{" "}
                {data.properties?.Age}
              </span>
              <span className="EditSpanFlex">
                <Icon
                  icon="mdi:map-marker"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Location:</span>{" "}
                {data.properties?.Location}
              </span>{" "}
              <span className="EditSpanFlex">
                <Icon
                  icon="material-symbols:height"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Measurement: </span>{" "}
                {data.properties?.Measurement}
              </span>
            </div>
            {/* /// */}
            <div className="EditContainer1">
              <span className="EditSpanFlex">
                <Icon
                  icon="mdi:human"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Race: </span>{" "}
                {data.properties?.Race}
              </span>
              <span className="EditSpanFlex">
                <Icon
                  icon="mdi:equal"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Service: </span>{" "}
                {data.properties?.Service}
              </span>
              <span className="EditSpanFlex">
                <Icon
                  icon="ph:money-fill"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Rate:</span>
                {data.properties?.Rate}
              </span>{" "}
              <span className="EditSpanFlex">
                <Icon
                  icon="game-icons:body-height"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Height:</span>{" "}
                {data.properties?.Height}
              </span>{" "}
              <span className="EditSpanFlex">
                <Icon
                  icon="mingcute:hair-line"
                  style={{ marginRight: "5px" }}
                  fontSize="20px"
                />
                <span style={{ marginRight: "5px" }}>Appearance:</span>{" "}
                {data.properties?.Appearance}
              </span>
              <span className="EditSpanFlex">
              <Icon
                icon="material-symbols:language"
                style={{ marginRight: "5px" }}
                fontSize="20px"
              />
              <span style={{ marginRight: "5px" }}>Enabled Languages:</span>
              {data?.languages ? (
                <span>
                  {data?.languages.join(", ")}
                </span>
              ) : (
                <span>No languages listed</span>
              )}
            </span>
            </div>
          </div>
          <hr className="BreakStyle" />
        </div>
        <div>
          <form>
            {/*Placeholders*/}
            <h4>Description</h4>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              class="custom-input"
            ></textarea>

            <hr className="BreakStyle" />
            <div class="button-container">
              <button
                class="save-button"
                onClick={(e) => {
                  updateBot(e);
                  navigate("/bots");
                }}
              >
                Save
              </button>
              <button class="exit-button" onClick={() => navigate("/bots")}>
                Exit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBot;
