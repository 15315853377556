import React, { useState, useEffect } from "react";
import styles from "./create.module.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "../../utils/authPopup.js";
import { useSelector } from "react-redux";
import PersonaInput from "./PersonaInput.js";
import "../../custom.css";
import BotFormText from "../../components/AddBot/BotFormText.jsx";
import BotFormDropdown from "../../components/AddBot/BotFormDropdown.jsx";
import BotFormCheckboxDropdown from "../../components/AddBot/BotFormDropdownCheck.jsx";
import PhoneNumbersSelector from "../../components/AddBot/PhoneNumbersSelector.jsx";
import LoadingButton from "../../components/shared/LoadingButton.js";
import handleError from "../../utils/handleError.js";

function CreateBots({ instance, organizationId }) {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  const [persona, setPersona] = useState([]);
  const [personaValue, setPersonaValue] = useState();
  const [areaNumber, setAreaNumber] = useState([]);
  const [botFieldsForm, setBotFieldsForm] = useState();
  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState();
  const [phoneValue, setPhoneValue] = useState("");
  const [description, setDescription] = useState("");
  const [requiredCheck, setRequiredCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [provisionNumber, setProvisionNumber] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([
    { id: 25, name: "English" },
  ]);
  const [languages, setLanguages] = useState([]);

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const showCheckBox = environment === "Staging" || environment === "Local";
  const countryCodes = [
    {
      name: "US",
    },
    {
      name: "CA",
    },
  ];

  useEffect(() => {
    if (
      selectedOperation &&
      personaValue &&
      description.length >= 3 &&
      phoneNumber.toString().length > 1 &&
      selectedLanguages.length != 0
    ) {
      setRequiredCheck(true);
    }
  }, [
    selectedOperation,
    personaValue,
    description,
    phoneNumber,
    selectedLanguages,
  ]);

  const createFunction = async () => {
    setLoading(true);
    const botObject = {
      ActuallyProvisionNumber: provisionNumber,
      name: botFieldsForm.Name,
      description: description,
      conversationStatistics: {
        countOfBotConversations: 10,
        countOfConversations: 20,
        countOfHumanConversations: 15,
      },
      languages: selectedLanguages.map((language) => language.name),
      isActive: true,
      organizationId: organizationId,
      phoneNumber: phoneNumber,
      operationId: selectedOperation.id,
      botPersonaId: personaValue.id,
      properties: botFieldsForm,
      botPersonaName: personaValue.name,
      responseDelaySecondsMax: 10,
      responseDelaySecondsMin: 5,
    };
    try {
      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/bots`,
        botObject
      );

      navigate("/bots");
    } catch (error) {
      handleError("An error occurred while creating the bot");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getOperations = async (msalContext) => {
    try {
      const response = await fetchWithAuth(
        msalContext,
        "GET",
        `api/organization/${organizationId}/Operations`
      );
      setOperations(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguages = async (msalContext) => {
    try {
      const response = await fetchWithAuth(
        msalContext,
        "GET",
        `api/organization/${organizationId}/languages`
      );

      const transformedLanguages = Object.keys(response).map((key, index) => ({
        id: index + 1, // Sequential ID
        name: key, // Language name
      }));
      console.log(transformedLanguages);
      setLanguages(transformedLanguages); // Return the transformed array
    } catch (error) {
      console.error("Error fetching or transforming languages:", error);
    }
  };

  const getBotPersonas = async (msalContext) => {
    try {
      const responce = await fetchWithAuth(
        msalContext,
        "GET",
        `api/botPersona`
      );
      setPersona(responce);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLanguageChange = (updatedSelections) => {
    setSelectedLanguages(updatedSelections);
  };

  useEffect(() => {
    getOperations(instance);
    getBotPersonas(instance);
    getLanguages(instance);
  }, [organizationId]);

  const setInitialForm = (persona) => {
    if (persona?.requiredProperties) {
      const initialFormState = {};
      persona.requiredProperties.forEach((property) => {
        initialFormState[property.name] = "";
      });
      setBotFieldsForm(initialFormState);
    }
  };

  const requestPhoneNumbers = async () => {
    if (!phoneValue)
      return window.confirm("Please fill the input with valid information");
    if (!countryCode?.name)
      return window.confirm("Please Choose the Country correctly ");

    try {
      const response = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${organizationId}/bots/available-numbers/${countryCode?.name}/${phoneValue}`
      );

      setAreaNumber(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.navigateBox}>
        <span className={styles.navigateBtn} onClick={() => navigate("/bots")}>
          All Bots
        </span>
        <Icon style={{ fontSize: "17px" }} icon="mingcute:right-fill" />
        New Bot
      </div>
      <div className={styles.titleBox}>
        <h2>New Bot</h2>
      </div>
      <hr style={{ margin: "7px 0px" }} />

      <div className={styles.formContainer}>
        <div className={`${themeClass} ${styles.propertiesSegment}`}>
          <h1>Bot Properties</h1>
          <div className={styles.botForm}>
            <BotFormText
              label="Bot Description"
              icon="fluent:text-description-16-filled"
              value={description}
              handleChange={(e) => setDescription(e.target.value)}
            />
            <BotFormDropdown
              label="Profile"
              icon="material-symbols:person"
              value={personaValue}
              handleClick={(element) => {
                setPersonaValue(element);
                setInitialForm(element);
              }}
              data={persona}
            />
            <BotFormCheckboxDropdown
              label="Enabled Languages"
              icon="material-symbols:language"
              selectedValues={selectedLanguages}
              handleClick={handleLanguageChange}
              data={languages}
            />
            {operations.length < 1 && (
              <span style={{ color: "red" }}>
                Please Create Operation First
              </span>
            )}
            <BotFormDropdown
              label="Operation"
              icon="eos-icons:organization"
              value={selectedOperation}
              handleClick={(element) => setSelectedOperation(element)}
              data={operations}
            />{" "}
            <BotFormDropdown
              label="Country"
              icon="streamline:contact-phonebook-2-solid"
              value={countryCode}
              handleClick={(e) => setCountryCode(e)}
              data={countryCodes}
            />
            <BotFormText
              label="Area Code"
              icon="material-symbols:phone-enabled-sharp"
              value={phoneValue}
              handleChange={(e) => setPhoneValue(e.target.value)}
            />
            <div className="buttonBox">
              <button onClick={requestPhoneNumbers} className="requestBtn">
                Request
              </button>
              <LoadingButton
                onClick={createFunction}
                disabled={!requiredCheck}
                loading={loading}
                style={{
                  padding: "5px 10px",
                  width: "130px",
                }}
              >
                Save
              </LoadingButton>
            </div>
            <PhoneNumbersSelector
              areaNumber={areaNumber}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              showCheckBox={showCheckBox}
              provisionNumber={provisionNumber}
              setProvisionNumber={setProvisionNumber}
            />
          </div>
        </div>

        <div className={`${themeClass} ${styles.detailsSegment}`}>
          <h1>Additional Details</h1>
          <div className={styles.personaForm}>
            {personaValue?.requiredProperties?.map((element) => (
              <PersonaInput
                key={element.name + element.type}
                botFieldsForm={botFieldsForm}
                value={botFieldsForm[element.name]}
                setBotFieldsForm={setBotFieldsForm}
                name={element.name}
                examples={element.examples}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBots;
