import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, CardTitle, CardText, Input } from "reactstrap";
import { Icon } from "@iconify/react";

import {
  OpsContainer,
  EditOpCard,
  EditBotTable,
  EditOperatorInput,
  MessageInput,
  SelectInput,
  EditTimeInput,
  StyledCard,
} from "./EditOperation.styled";

import { InfoIcon } from "../../shared/styles/InfoIcon.styled";
import { PrimaryButton } from "../../shared/styles/Buttons.styled";
import { useParams } from "react-router-dom";
import { fetchWithAuth } from "../../utils/authPopup";
import LoadingButton from "../../components/shared/LoadingButton";

export default function EditOperation({ instance, orgId }) {
  const navigate = useNavigate();
  const [deterrenceMessage, setDeterrenceMessage] = useState("");
  const [deterrenceMessageValidation, setDeterrenceMessageValidation] =
    useState("");
  const [valid, setValid] = useState(true);
  const [validLoading, setValidLoading] = useState(false);
  const [zipCodes, setZipCodes] = useState([]);
  const [location, setLocation] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [bots, setBots] = useState([]);
  const [city, setCity] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setzipCode] = useState("");

  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [endTime, setEndTime] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const fileInputRef = useRef(null);

  async function getOperationsfull(msalContext) {
    const opsList = await fetchWithAuth(
      msalContext,
      "GET",
      `api/organization/${orgId}/operations/${id}`
    );

    return opsList;
  }

  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setPreviewSrc(URL.createObjectURL(e.target.files[0]));
  };
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewSrc(null);
    fileInputRef.current.value = null;
  };
  const editOperation = async () => {
    setLoading(true);
    if (!valid) {
      alert("Please Validate The Zip Code.");
      setLoading(false);

      return;
    }
    try {
      const object = {
        name: name,
        startDate: new Date(startTime).toISOString(),
        endDate: new Date(endTime).toISOString(),
        deterrenceMessage: deterrenceMessage,
        fileUrl: "",
        location: location,

        locationDetails: {
          city: city,
          country: country,

          state: state,
          zipcode: zipcode,
          lat: lat,
          lng: lng,
        },
      };

      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("operationId", id);

        const data = await fetchWithAuth(
          instance,
          "POST",
          `api/organization/${orgId}/media/upload`,
          formData
        );
        object.fileUrl = data.url;
      }

      handleRemoveFile();

      await fetchWithAuth(
        instance,
        "PUT",
        `api/organization/${orgId}/operations/${id}`,
        object
      );

      navigate(`/operations/operation/${id}`);
    } catch (err) {
      alert(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOperationsfull(instance).then((response) => {
      setName(response.name);
      setLocation(response.location);
      setStartTime(formatDateForInput(response.startDate));
      setEndTime(formatDateForInput(response.endDate));
      setBots(response.bots);
      setDeterrenceMessage(response.deterrenceMessage);
      setPreviewSrc(response.fileUrl);
      setCountry(response.locationDetails.country);
      setzipCode(response.locationDetails.zipcode);
      setCity(response.locationDetails.city);
      setState(response.locationDetails.state);
      setLat(response.locationDetails.lat);
      setLng(response.locationDetails.lon);
    });
  }, []);

  const handleDeterrenceMessageOptionChange = (event) => {
    setDeterrenceMessage(event.target.value);
  };

  const { id } = useParams();

  const handleDeterrenceMessageChange = (event) => {
    setDeterrenceMessage(event.target.value);
  };

  useEffect(() => {
    if (deterrenceMessage.trim() === "" || deterrenceMessage.length < 30) {
      setDeterrenceMessageValidation(
        "Deterrence message must be at least 30 characters long"
      );
    } else {
      setDeterrenceMessageValidation("");
    }
  }, [deterrenceMessage]);

  const handleInputChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEndTime(e.target.value);
  };
  const styles = {
    fileContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    fileUpload: {
      input: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        zIndex: 1000,
      },
      img: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    },
    previewImg: {
      width: "50px",
      height: "50px",
      position: "absolute",
      left: "5px",
      bottom: "5px",
      cursor: "pointer",
    },
  };
  const getZip = async () => {
    try {
      if (zipcode) {
        setValidLoading(true);
        const data = await fetchWithAuth(
          instance,
          "GET",
          `api/location/${zipcode}`
        );
        if (data.results.length > 0) {
          setZipCodes(data);
          setValid(true);
          setCountry(data?.results[0]?.address?.countryCode);
          setState(data?.results[0]?.address?.countrySubdivisionName);
          setCity(data?.results[0]?.address?.postalName);
          setLat(data?.results[0]?.position.lat);
          setLng(data?.results[0]?.position.lon);
          setLocation(data?.results[0]?.address?.postalName);
          console.log(data);
          setValidLoading(false);
        } else {
          setValid(false);
          setValidLoading(false);
        }
      } else {
        alert("Fill Zip Code Filed");
      }
    } catch (error) {
      console.error("Error fetching zip codes:", error);
    }
  };

  const handleZipValid = (e) => {
    setzipCode(e.target.value);
    setValid(false);
  };
  return (
    <OpsContainer>
      <p>
        <Link to="/operations" style={{ textDecoration: "none" }}>
          All Operations
        </Link>
        &gt;&nbsp;
        <button
          onClick={() => navigate(`/operations/operation/${id}`)}
          style={{
            textDecoration: "none",
            backgroundColor: "transparent",
            color: "#093ccc",
            border: "none",
          }}
        >
          Operation Information
        </button>
        &gt; Edit Operation
      </p>
      <div className="d-flex justify-content-between align-items-start">
        <h1>Edit Operation</h1>
        <div className="d-flex align-items-end justify-content-between">
          <button
            style={{
              textDecoration: "none",
              backgroundColor: "transparent",
              color: "#093ccc",
              border: "none",
            }}
            onClick={() => navigate(`/operations/operation/${id}`)}
          >
            Back <InfoIcon icon="pajamas:go-back" color="#46a6ff" />
          </button>
        </div>
      </div>
      <Row className="mt-3">
        <Col md="8">
          <Row>
            <Col>
              <h2>
                <b>Operation Settings</b>
              </h2>
              <EditOpCard body>
                <CardText>
                  <Row>
                    <Col md="5" style={{ width: "100%" }}>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ph:info-fill" color="white" />
                          Name
                        </Col>
                        <Col>
                          <EditOperatorInput
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ph:info-fill" color="white" />
                          Country
                        </Col>
                        <Col>
                          <h5
                            style={{
                              padding: "10px",
                              margin: "10px",
                              width: "100%",
                            }}
                          >
                            {country}
                          </h5>
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ph:info-fill" color="white" />
                          State
                        </Col>
                        <Col>
                          <h5
                            style={{
                              padding: "10px",
                              margin: "10px",
                              width: "100%",
                            }}
                          >
                            {" "}
                            {state}
                          </h5>
                        </Col>
                      </Row>

                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="mdi:location" color="white" />
                          Location
                        </Col>
                        <Col>
                          <h5
                            style={{
                              padding: "10px",
                              margin: "10px",
                              width: "100%",
                            }}
                          >
                            {location}
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ph:info-fill" color="white" />
                          City
                        </Col>
                        <Col>
                          <EditOperatorInput
                            value={city}
                            readOnly
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ph:info-fill" color="white" />
                          Zipcode
                        </Col>
                        <Col style={{ paddingRight: "0" }}>
                          <div
                            style={{
                              width: "100$",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <EditOperatorInput
                              style={{ width: "30%" }}
                              type="text"
                              name="Zip Code"
                              id="zipcode"
                              value={zipcode}
                              onChange={(e) => handleZipValid(e)}
                            />{" "}
                            {valid ? (
                              <InfoIcon
                                style={{ margin: "0" }}
                                icon="fluent-mdl2:completed-solid"
                                color="#7ef182"
                              />
                            ) : (
                              <InfoIcon
                                style={{ margin: "0" }}
                                icon="fluent-mdl2:status-error-full"
                                color="#f17e7e"
                              />
                            )}
                            <LoadingButton
                              onClick={() => getZip()}
                              loading={validLoading}
                            >
                              Validate Zip Code
                            </LoadingButton>{" "}
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ri:time-line" color="white" />
                          Start Time
                        </Col>
                        <Col>
                          <EditTimeInput
                            type="datetime-local"
                            value={startTime}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center justify-content-center">
                        <Col md="4" xs="4">
                          <InfoIcon icon="ri:time-line" color="white" />
                          End Time
                        </Col>
                        <Col>
                          <EditTimeInput
                            type="datetime-local"
                            value={endTime}
                            onChange={handleInputChange2}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardText>
              </EditOpCard>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col>
              <h2>
                <b>Deterrence Message</b>
              </h2>
              <SelectInput
                type="select"
                value={deterrenceMessage}
                onChange={handleDeterrenceMessageOptionChange}
              >
                <option value="You have been warned! This entire conversation has been recorded and documented in a permanent file with your name and phone number. This information is now and remains available to ALL local, state, and federal law enforcement agencies. Soliciting sex from a minor is illegal and a felony in all 50 states. It is punishable by up to 50 years in prison and $100,000+ in fines. Even the attempt to purchase sex from a minor is a FELONY! For your sake and for the sake of others, please consider getting help now before it is too late. Remember, any and all further attempts to purchase sex will be reported immediately. CONSIDER THIS YOUR FIRST, LAST AND ONLY WARNING!">
                  You have been warned!
                </option>{" "}
                <option
                  value=" You have recently been identified
                  You have recently been identified as soliciting sex from a
                  minor; this is a FELONY with potentially severe consequences
                  of fines, prison, and being added to the national sex offender
                  registry. Buying sex is illegal and can cause serious
                  long-term harm to yourself, your loved ones, and the person
                  being victimized. This “is” HUMAN TRAFFICKING! The details of
                  this incident will be reviewed further, and law enforcement
                  may be contacted, questioned or charged by local, state, or
                  federal law enforcement. For your sake and for the sake of
                  others, please consider getting help now before it is too
                  late. Remember, any and all further attempts to purchase sex
                  will be reported immediately."
                >
                  You have recently been identified
                </option>
                <option value="ATTENTION: You are now permanently registered in the system as someone seeking to purchase sex online from an exploited individual. The people in these ads are under force, fraud, and coercion, and you are directly contributing to their abuse by being part of the demand for HUMAN TRAFFICKING. The details of this incident will be reviewed further, and law enforcement may be contacted, questioned or charged by local, state, or federal law enforcement. For your sake and for the sake of others, please consider getting help now before it is too late. Remember, any and all further attempts to purchase sex will be reported immediately. ">
                  ATTENTION
                </option>
              </SelectInput>{" "}
              <EditOpCard className="mb-0" body>
                <MessageInput
                  value={deterrenceMessage}
                  onChange={handleDeterrenceMessageChange}
                  rows="10"
                />
                <StyledCard>
                  <LoadingButton
                    onClick={editOperation}
                    loading={loading}
                    disabled={deterrenceMessageValidation !== ""}
                    primary
                  >
                    Save
                  </LoadingButton>
                  <div style={{ position: "relative" }}>
                    <Icon icon="ic:sharp-upload-file" fontSize={26} />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,

                        height: "100%",
                        opacity: 0,
                        zIndex: 1000,
                      }}
                    />
                  </div>
                  <p style={{ color: "red", margin: 0 }}>
                    {deterrenceMessageValidation}
                  </p>{" "}
                  <p
                    style={{
                      margin: 0,
                      position: "absolute",
                      right: 0,
                      top: 2,
                    }}
                  >
                    {previewSrc && (
                      <img
                        src={previewSrc}
                        alt="preview"
                        style={{
                          width: "50px",
                          height: "50px",

                          cursor: "pointer",
                        }}
                        onClick={handleRemoveFile}
                      />
                    )}{" "}
                  </p>{" "}
                </StyledCard>{" "}
              </EditOpCard>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <EditOpCard className="h-100 m-3 p-4" body>
            <div className="d-flex justify-content-between">
              <CardTitle tag="h2">
                <b>Bots</b>
              </CardTitle>
              <PrimaryButton onClick={() => navigate("/bots/create-bot")}>
                <Icon icon="ic:baseline-plus" color="white" /> Add new
              </PrimaryButton>
            </div>
            <EditBotTable className="mt-3">
              <thead>
                <th>Name</th>
                <th>Phone Number</th>
              </thead>
              <tbody style={{ fontSize: "18px" }}>
                {bots?.map((el) => {
                  return (
                    <tr>
                      <td>{el?.properties?.Name}</td>
                      <td>{el?.phoneNumber}</td>
                    </tr>
                  );
                })}
              </tbody>
            </EditBotTable>
          </EditOpCard>
        </Col>
      </Row>
    </OpsContainer>
  );
}
